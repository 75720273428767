import React, { forwardRef, InputHTMLAttributes, Ref } from 'react';

import cs from 'classnames';

type InputProps = {
  className?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'className'>;

const Input = forwardRef(
  ({ className, ...rest }: InputProps, ref: Ref<HTMLInputElement>) => {
    const classNames = cs(
      'outline-none rounded h-[40px] tl:h-[44px] p-2 border border-light-100 focus:border-[1.5px] focus:border-primary outline-0 bg-input',
      className
    );
    return <input className={classNames} {...rest} ref={ref} />;
  }
);

export default Input;
