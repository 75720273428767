import { StateCreator } from 'zustand';

import { OrderSliceDef } from '@/models/cart.types';

export const qrCodeSlice: StateCreator<OrderSliceDef> = (set) => ({
  qrImage: '',
  shopName: '',
  setQrImage: (qrImage) => set({ qrImage }),
  setShopName: (shopName) => set({ shopName }),
});
