import React, { useEffect, useState } from 'react';

import Image from 'next/image';

import ImageRotation from '@/assets/images/device-rotation-inform.png';

const DeviceRotationNotify = () => {
  const [isOrientationLandscape, setIsOrientationLandscape] = useState(false);
  useEffect(() => {
    const orientationChange = () => {
      const isLandscapeMode = [90, -90].includes(+window.orientation);
      isLandscapeMode
        ? document.documentElement.classList.add('hidden-landscape')
        : document.documentElement.classList.remove('hidden-landscape');
      setIsOrientationLandscape(isLandscapeMode);
    };
    window.addEventListener('orientationchange', orientationChange);
    orientationChange();

    return () =>
      window.removeEventListener('orientationchange', orientationChange);
  }, []);
  return (
    <div
      className={`device-rotation-notify ${
        isOrientationLandscape ? '!flex' : ''
      }`.trim()}
    >
      <Image
        className="device-rotation-notify-image"
        width={200}
        height={0}
        src={ImageRotation}
        alt="Device rotation notify"
      />
    </div>
  );
};

export default DeviceRotationNotify;
