import React from 'react';

import IconLoadingSpin from '@/assets/icons/icon-loading-spin.svg';

const LoadingOrder = () => {
  return (
    <div className="fixed w-full h-full z-50 bg-neutral-100">
      <div className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-1/2 select-none">
        <IconLoadingSpin className="mx-auto my-0 animate-spin" />
        <p className="text-sm tl:text-base text-note mt-10">
          ローディング中...
        </p>
      </div>
    </div>
  );
};

export default LoadingOrder;
