import React, { useEffect, useState } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';
import qs from 'query-string';

import ImageDefaultOG from '@/assets/images/og.png';
import { METADATA } from '@/constants';

type SEOProps = {
  isArticle?: boolean;
  noIndex?: boolean;
  author?: string;
  screenName: keyof typeof METADATA;
  children?: React.ReactNode;
};

const SEO = ({
  noIndex,
  isArticle,
  author,
  screenName,
  children,
}: SEOProps) => {
  const { pathname, query } = useRouter();
  const [isIOSSafari, setIsIOSSafari] = useState(false);

  const canonicalUrl = qs.stringifyUrl(
    {
      url: `${process.env.NEXT_PUBLIC_APP_URL}${pathname}`,
      query: isIOSSafari ? query : {},
    },
    { skipEmptyString: true, skipNull: true }
  );

  const seo = {
    title: METADATA[screenName].title || '',
    description: METADATA[screenName].description || '',
    image: `${process.env.NEXT_PUBLIC_APP_URL}${ImageDefaultOG.src}`,
    url: canonicalUrl,
  };

  const seoOGP = {
    author: author || '',
    title: 'TAG LIVE LABEL | お客様の体験を特別な1本で',
    url: process.env.NEXT_PUBLIC_APP_URL,
    description:
      'TAG LIVE LABELは、オリジナルラベルドリンクをその場で作成・販売できる法人向けサービスです。',
  };

  useEffect(() => {
    setIsIOSSafari(
      (/iP(ad|od|hone)/i.test(window.navigator.userAgent) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) &&
        !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
    );
  }, []);

  return (
    <Head>
      <title>{seo.title}</title>
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      {seoOGP.author && (
        <>
          <meta name="author" content={seoOGP.author} />
          <meta property="og:author" content={seoOGP.author} />
          <meta name="twitter:author" content={seoOGP.author} />
        </>
      )}
      <meta name="image" content={seo.image} />
      <meta property="og:type" content={isArticle ? 'article' : 'website'} />
      <meta property="og:url" content={seoOGP.url} />
      <meta property="og:title" content={seoOGP.title} />
      <meta property="og:description" content={seoOGP.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:site_name" content="TAG LIVE LABEL" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seoOGP.title} />
      <meta name="twitter:description" content={seoOGP.description} />
      <meta name="twitter:image" content={seo.image} />
      {noIndex && <meta name="robots" content="noindex,follow" />}
      {children}
    </Head>
  );
};

export default SEO;
