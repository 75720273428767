const ROUTES_PATH = {
  top: '/',
  labelSelection: '/label-selection',
  labelPreview: '/label-preview',
  labelConfirm: '/label-confirm',
  labelOrder: '/label-order',
  labelQR: '/label-qr',
  error: '/error',
  notFound: '/404',
} as const;

export { ROUTES_PATH };
