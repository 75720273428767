import '../styles/globals.css';
import type { ReactElement, ReactNode } from 'react';
import { useEffect } from 'react';

import { Noto_Sans_JP } from '@next/font/google';
import localFont from '@next/font/local';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useCountUp } from 'react-countup';
import { SWRConfig } from 'swr';

import { AxiosInterceptor } from '@/api/api';
import { Modal as OutDatedModal, SEOComponent } from '@/components/modules';
import DeviceRotationNotify from '@/components/modules/DeviceRotationNotify';
import { ROUTES_PATH } from '@/constants';
import { useRouterWithQueryParams } from '@/hooks';
import { useAppStore } from '@/store';

export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const notoSansJP = Noto_Sans_JP({
  display: 'swap',
  subsets: ['japanese'],
  weight: ['400', '500', '700', '900'],
});

const gothicBold = localFont({
  weight: '700',
  src: '../assets/fonts/TradeGothicLTPro-BdCn20.woff2',
});

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const { pathname, push } = useRouter();
  const { isOutDated, clearCart } = useAppStore();
  const {
    isReady,
    query: { shopMode },
    generateUrlWithQueryParams,
  } = useRouterWithQueryParams();
  const topUrl = generateUrlWithQueryParams(ROUTES_PATH.top);

  const { countUp, start } = useCountUp({
    start: 60,
    end: 0,
    duration: 60,
    useEasing: false,
    startOnMount: false,
  });

  const handleStart = () => {
    start();
  };

  useEffect(() => {
    const onBackButtonEvent = () => window.history.forward();

    window.history.pushState(null, '', window.location.href);
    window.history.back();
    window.addEventListener('popstate', onBackButtonEvent);

    return () => window.removeEventListener('popstate', onBackButtonEvent);
  }, []);

  useEffect(() => {
    if (isReady && shopMode) {
      start();
      document.addEventListener('click', handleStart);
      document.addEventListener('scroll', handleStart);
    }

    return () => {
      if (isReady && shopMode) {
        document.removeEventListener('click', handleStart);
        document.removeEventListener('scroll', handleStart);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  useEffect(() => {
    if (+countUp) return;

    if (
      !([ROUTES_PATH.top, ROUTES_PATH.labelQR] as string[]).includes(pathname)
    ) {
      clearCart();
      push(topUrl);
    }
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countUp]);

  return getLayout(
    <SWRConfig
      value={{
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }}
    >
      <SEOComponent screenName="top" noIndex>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </SEOComponent>
      <style jsx global>
        {`
          :root {
            --noto-sans-font: ${notoSansJP.style.fontFamily};
            --gothic-bold-font: ${gothicBold.style.fontFamily};
          }
        `}
      </style>
      <AxiosInterceptor />
      <Component {...pageProps} />
      <DeviceRotationNotify />
      <OutDatedModal
        visible={isOutDated}
        onOk={() => window.location.reload()}
        content="キャッシュクリアのため、画面をリロードしてください"
        modalOptions={{ okText: '再読み込み', showCancelText: false }}
      />
    </SWRConfig>
  );
}
