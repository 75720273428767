const MAIN_TEXT_FONT_SIZE_MIN = 30;
const MAIN_TEXT_FONT_SIZE_MAX = 51.5;
const MAIN_TEXT_PRINT_FONT_SIZE_MIN = 70;
const MAIN_TEXT_PRINT_FONT_SIZE_MAX = 116;
const MAIN_TEXT_PRINT_MAX_WIDTH = 727;
const MAIN_TEXT_DEFAULT_VALUE = 'YOUR NAME';
const MAIN_TEXT_KERNING = 1;

export {
  MAIN_TEXT_FONT_SIZE_MIN,
  MAIN_TEXT_FONT_SIZE_MAX,
  MAIN_TEXT_PRINT_MAX_WIDTH,
  MAIN_TEXT_DEFAULT_VALUE,
  MAIN_TEXT_PRINT_FONT_SIZE_MIN,
  MAIN_TEXT_PRINT_FONT_SIZE_MAX,
  MAIN_TEXT_KERNING,
};
