import { useRouter } from 'next/router';
import qs from 'query-string';

type QueryParamsDef = {
  shopId?: string;
  shopMode?: string;
  orderHash?: string;
};

const useRouterWithQueryParams = (): {
  isReady: boolean;
  query: QueryParamsDef;
  generateUrlWithQueryParams: (url: string) => string;
} => {
  const { query, isReady } = useRouter();

  const generateUrlWithQueryParams = (url: string) =>
    qs.stringifyUrl(
      { url, query: { shopMode: query?.shopMode, shopId: query?.shopId } },
      { skipEmptyString: true, skipNull: true }
    );

  return { isReady, query, generateUrlWithQueryParams };
};

export default useRouterWithQueryParams;
