// @ts-noCheck
import fontLib from '@/assets/fonts/tag/_font.json';
import kern10 from '@/assets/fonts/tag/_kern+10.json';
import kern12 from '@/assets/fonts/tag/_kern+12.json';
import kern15 from '@/assets/fonts/tag/_kern+15.json';
import kern5 from '@/assets/fonts/tag/_kern+5.json';
import kern8 from '@/assets/fonts/tag/_kern+8.json';
import kern from '@/assets/fonts/tag/_kern.json';
import offsetLib from '@/assets/fonts/tag/_offset.json';
import {
  MAIN_TEXT_FONT_SIZE_MIN,
  MAIN_TEXT_KERNING,
  MAIN_TEXT_PRINT_FONT_SIZE_MAX,
  MAIN_TEXT_PRINT_FONT_SIZE_MIN,
  MAIN_TEXT_PRINT_MAX_WIDTH,
} from '@/constants';
import { convertToHalfWidth } from '@/helpers';
import { TagFontDef, TagFontResultDef } from '@/models/tagfont.types';

const getPosition = (targets: string, kernJSON: string) => {
  let prev = '';
  const step = [];
  const offset = [];

  step[0] = 0;

  for (let i = 0; i < targets?.length; i++) {
    const next = targets[i];

    if (/^[0-9]+$/i.test(next)) {
      offset[i] = offsetLib[next];
      step[i] -= offset[i];
    } else {
      offset[i] = 0;
    }

    if (prev !== '') {
      const move =
        kernJSON === 'kern'
          ? kern[prev + next]
          : kernJSON === 'kern5'
          ? kern5[prev + next]
          : kernJSON === 'kern8'
          ? kern8[prev + next]
          : kernJSON === 'kern10'
          ? kern10[prev + next]
          : kernJSON === 'kern12'
          ? kern12[prev + next]
          : kern15[prev + next];
      step[i] = move;
    }
    prev = next;
  }

  return { step, offset };
};

const tagFontHelper = (value: string, fontSize: number, kernJSON = 'kern') => {
  const tagFont: TagFontDef = {
    content: [],
    maxLength: 0,
    validationError: '',
  };

  if (value) {
    const array = convertToHalfWidth(value).split('');
    const position = getPosition(value, kernJSON);
    const K = (fontSize / 841) * fontLib['A'].scale;
    const result: TagFontResultDef[] = [];
    let positionX = 0;
    let maxLength = 0;

    array.forEach((item, i) => {
      positionX += position.step[i] * K;
      const positionLeft: number = positionX + position.offset[i] * K;
      if (fontLib[item]) {
        result.push({
          image: `/char-images/${fontLib[item].code}.svg`,
          name: item,
          style: {
            position: 'absolute',
            bottom: `${
              fontSize === 49
                ? 0.03
                : fontSize === 46.5
                ? 0.04
                : fontSize === 44
                ? 0.08
                : fontSize === 41.5
                ? 0.1
                : fontSize === 39
                ? 0.1
                : 0
            }rem`,
            left: 0,
            transform: `translateX(${positionLeft.toFixed(2)}px)`,
            height: `${fontSize}px`,
          },
          length: Math.ceil(
            positionLeft + (fontLib[item].width * fontSize) / 841
          ),
        });
        maxLength = Math.ceil(
          positionLeft + (fontLib[item].width * fontSize) / 841
        );
      } else {
        result.push({ invalid: item });
      }
    });
    tagFont.content = result;
    tagFont.maxLength = maxLength;
  } else {
    tagFont.content = [];
    tagFont.maxLength = 0;
  }

  return tagFont;
};

const getTagFont = (
  value: string,
  fontSize: number,
  defaultValue: string,
  kernJSON = 'kern'
) => {
  let inputTagFont;
  let validationError;
  let mainTextMaxFontSize = MAIN_TEXT_PRINT_FONT_SIZE_MAX;
  let maxFontSize = fontSize;
  let isInvalidFontSize;
  validationError = '';
  isInvalidFontSize = false;
  // get max length for print
  const maxLengthPrint = getPrintWidth(
    value,
    mainTextMaxFontSize,
    MAIN_TEXT_KERNING
  );
  // define multiplier for main text
  const multipierFontSize = MAIN_TEXT_PRINT_MAX_WIDTH / maxLengthPrint;
  // if multiplier < 1, then we need to reduce font size
  if (multipierFontSize < 1) {
    // reduce font size
    mainTextMaxFontSize = mainTextMaxFontSize * multipierFontSize;
    maxFontSize = maxFontSize * multipierFontSize;
    // if font size < min font size, then we need to set min font size
    if (mainTextMaxFontSize < MAIN_TEXT_PRINT_FONT_SIZE_MIN) {
      mainTextMaxFontSize = MAIN_TEXT_PRINT_FONT_SIZE_MIN;
      maxFontSize = MAIN_TEXT_FONT_SIZE_MIN;
      isInvalidFontSize = true;
    }
  }
  // make tagfont
  inputTagFont = tagFontHelper(value, maxFontSize, kernJSON);
  // check if the text has invalid letters
  const invalidLetters = inputTagFont.content.filter((item) => item.invalid);
  if (invalidLetters?.length) {
    const indexInvalid = inputTagFont.content.findIndex((item) => item.invalid);
    if (!indexInvalid) {
      inputTagFont.content = tagFontHelper(
        defaultValue,
        maxFontSize,
        kernJSON
      ).content;
      validationError = 'notMatchRule';
    } else {
      inputTagFont.content = inputTagFont.content.slice(0, indexInvalid);
      validationError = 'notMatchRule';
    }
  } else {
    if (isInvalidFontSize) {
      validationError = 'overMaxLength';
    }
  }
  inputTagFont = { ...inputTagFont, validationError };
  return inputTagFont;
};

const fontSizeMultiplier = 1 / 0.75;

const getPrintWidth = (value: string, fontSize: number, kerning = 1) => {
  let position = 0;
  if (!value) return position;

  let kerningJson = 0;
  const array = convertToHalfWidth(value).split('');
  array.forEach((char, index) => {
    if (index) {
      const v = getKerning(array[index - 1], char, fontSize, 1);
      kerningJson = v;
    }
    position += kerningJson * kerning;
  });
  const charWidth = getCharWidth(array[array.length - 1], fontSize);
  position += charWidth;
  return position;
};

const getCharWidth = (char: string, fontSize: number) => {
  const fontSizeScale = getFontSizeScale(fontSize);
  return fontLib[char]?.width * fontSizeScale * (1 / fontLib[char]?.scale);
};

const getKerning = (
  prevChar: string,
  curChar: string,
  fontSize: number,
  kerningMultiplier: number
) => {
  const fontSizeScale = getFontSizeScale(fontSize);
  return kern[`${prevChar}${curChar}`] * fontSizeScale * kerningMultiplier;
};

const getFontSizeScale = (fontSize: number) =>
  (fontSize * fontSizeMultiplier) / 1000;

export { tagFontHelper, getTagFont };
