import React from 'react';

import cs from 'classnames';

import { TagFontDef } from '@/models/tagfont.types';

export type TagFontItemProps = {
  tagFont: TagFontDef;
  fontSize: number;
  className?: string;
};

const TagFontItem = ({ tagFont, fontSize, className }: TagFontItemProps) => (
  <div
    className={cs(className)}
    style={{
      width: tagFont?.maxLength || 0,
      height: `${fontSize}px`,
    }}
  >
    {tagFont?.content?.map((item, index) => {
      if (item.image) {
        return (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            key={index}
            src={item.image}
            style={{ ...item.style }}
            alt={item.name}
          />
        );
      }
    })}
  </div>
);

export default TagFontItem;
