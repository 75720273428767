import React from 'react';

import cs from 'classnames';

import IconInfoTablet from '@/assets/icons/info-tablet.svg';
import IconInfo from '@/assets/icons/info.svg';

import Button from '../Button';

type ModalProps = {
  visible: boolean;
  content?: React.ReactNode;
  onOk: () => void;
  onCancel?: () => void;
  modalOptions?: {
    okText?: string;
    showCancelText?: boolean;
  };
};

const Modal = ({
  visible,
  content,
  modalOptions,
  onOk,
  onCancel,
}: ModalProps) => {
  const { okText, showCancelText = true } = modalOptions || {};
  const classNames = cs(
    'fixed bg-modal flex-centered transition-[visibility] transition-[opacity] duration-200 inset-0',
    { visible, 'invisible opacity-0': !visible }
  );

  const modalWrapperClassNames = cs(
    'mx-4 tl:mx-6 bg-white flex-1 rounded p-6 shadow-modal transition-scale duration-200 scale-0 max-w-lg',
    { 'scale-100': visible }
  );

  return (
    <div className={classNames}>
      <div className={modalWrapperClassNames}>
        <div className="flex items-center">
          <IconInfo className="tl:hidden" />
          <IconInfoTablet className="hidden tl:block" />
          <p className="flex-1 ml-2 tl:ml-4 text-sm tl:text-base text-primary">
            {content || (
              <>
                注文内容を破棄してTOP画面に戻ります。 <br />
                よろしいですか？
              </>
            )}
          </p>
        </div>
        <div className="mt-6 flex">
          {showCancelText ? (
            <Button
              className="flex-1 border border-primary h-[48px] font-medium text-13 tl:text-15"
              onClick={onCancel}
            >
              キャンセル
            </Button>
          ) : (
            <span className="flex-1" />
          )}
          <Button
            btnType="primary"
            className="flex-1 h-[48px] ml-4 font-medium text-13 tl:text-15"
            onClick={onOk}
          >
            {okText || 'OK'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
