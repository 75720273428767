import { StateCreator } from 'zustand';

import { CartSliceDef } from '@/models/cart.types';

export const cartSlice: StateCreator<CartSliceDef> = (set, get) => ({
  cart: [],
  selectedLabel: null,
  inputtedMainText: null,
  editingCartItemIndex: null,
  setEditingCartItemIndex: (index) => set({ editingCartItemIndex: index }),
  setLabel: (label) => set({ selectedLabel: label }),
  setMainText: (mainText) => set({ inputtedMainText: mainText }),
  addToCart: (cartItem) => set({ cart: [...get().cart, cartItem] }),
  updateCartItem: (cartItem, index) => {
    const cart = get().cart;
    cart[index] = cartItem;
    set({ cart });
  },
  removeCartItem: (index) => {
    const cart = get().cart;
    cart.splice(index, 1);
    set({ cart });
  },
  clearCart: () => set({ cart: [] }),
});
