import React from 'react';

import cs from 'classnames';
import Link from 'next/link';

import IconCartTablet from '@/assets/icons/cart-tablet.svg';
import IconCart from '@/assets/icons/cart.svg';
import { ROUTES_PATH } from '@/constants';
import { useRouterWithQueryParams } from '@/hooks';
import { useAppStore } from '@/store';

const Cart = () => {
  const { cart } = useAppStore();
  const { generateUrlWithQueryParams } = useRouterWithQueryParams();
  const labelConfirmUrl = generateUrlWithQueryParams(ROUTES_PATH.labelConfirm);
  const classNames = cs(
    'absolute rounded-full font-bold border w-[21px] tl:w-[26px] h-[21px] tl:h-[26px] bg-white text-primary flex-centered text-xs tl:text-sm top-[2px] tl:top-[3px] left-[28px] tl:left-[36px]',
    {
      'text-[10px] tl:text-xs': cart?.length > 99,
    }
  );

  return (
    <div className="fixed rounded-full border w-[50px] tl:w-[64px] h-[50px] tl:h-[64px] bg-primary text-white flex-centered right-[16px] bottom-[16px] tl:bottom-[24px]">
      <Link href={labelConfirmUrl}>
        <IconCartTablet className="hidden tl:block" />
        <IconCart className="tl:hidden" />
        <div className={classNames}>
          {cart?.length > 99 ? '99+' : cart?.length}
        </div>
      </Link>
    </div>
  );
};

export default Cart;
