import { ScreenName, MetadataItemDef } from '@/models/metadata.types';

const METADATA = {
  top: {
    title: 'TAG LIVE LABEL 店頭注文システム',
    description:
      'TAG LIVE LABELは、オリジナルラベルドリンクをその場で作成・販売できる法人向けサービスです。',
  },
} as Record<ScreenName, MetadataItemDef>;

export { METADATA };
