import React from 'react';

import IconLoading from '@/assets/icons/loading.svg';

const Loading = () => {
  return (
    <div className="fixed w-full h-full z-50 bg-neutral-100">
      <IconLoading className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-1/2 select-none" />
    </div>
  );
};

export default Loading;
