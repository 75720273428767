export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const convertToHalfWidth = (s: string) => {
  return s
    .replace(/[\uff01-\uff5e]/g, (fullWidthChar) =>
      String.fromCharCode(fullWidthChar.charCodeAt(0) - 0xfee0)
    )
    .replace(/\u3000/g, '\u0020')
    .replace(/[ー]/g, '-'); // hyphen filter holder of label
};

export const formatCharacter = (str: string) => {
  return str.replace(/[“”]/g, '"').replace(/[‘’]/g, "'").replace(/[•]/g, '·');
};
