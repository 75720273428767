import { useEffect } from 'react';

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useRouter } from 'next/router';
import qs from 'query-string';

import { API_STATUS_CODE, ROUTES_PATH } from '@/constants';
import { useRouterWithQueryParams } from '@/hooks';
import { useAppStore } from '@/store';

export type CustomAxiosRequestConfig = AxiosRequestConfig & {
  ignoreError?: boolean;
  errUrls?: Record<keyof typeof API_STATUS_CODE, string>;
};

const api = axios.create({
  timeout: 30000,
  baseURL: process.env.NEXT_PUBLIC_API_HOST,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
    'Accept-version': process.env.NEXT_PUBLIC_API_VERSION,
  },
  paramsSerializer: {
    serialize: (params) =>
      qs.stringify(params, { skipEmptyString: true, skipNull: true }),
  },
});

api.interceptors.request.use(
  (config: CustomAxiosRequestConfig) => config,
  (error) => Promise.reject(error)
);

const AxiosInterceptor = () => {
  const { push } = useRouter();
  const { clearCart, setIsOutDated } = useAppStore();
  const { isReady, generateUrlWithQueryParams } = useRouterWithQueryParams();
  const errorUrl = generateUrlWithQueryParams(ROUTES_PATH.error);
  const notFoundUrl = generateUrlWithQueryParams(ROUTES_PATH.notFound);

  useEffect(() => {
    const resInterceptor = (response: AxiosResponse) => response;

    const errInterceptor = (error: AxiosError) => {
      const config = error.config as CustomAxiosRequestConfig;
      const errorUrls = config?.errUrls;
      const errorStatus = error?.response?.status;
      if (errorStatus === 426) {
        setIsOutDated(true);
      } else {
        if (!config?.ignoreError) {
          const errorStatus = error.response?.status;
          const isNotFound = errorStatus === API_STATUS_CODE.notFound;
          push(
            isNotFound
              ? errorUrls?.notFound || notFoundUrl
              : errorUrls?.error || errorUrl
          );
          clearCart();
        }
      }
      return Promise.reject(error);
    };

    const interceptor = api.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => api.interceptors.response.eject(interceptor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  return null;
};

export { AxiosInterceptor };

export default api;
