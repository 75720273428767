type IdDef = number | string | undefined;

const API_STATUS_CODE = {
  error: 'error',
  notFound: 404,
} as const;

const API_END_POINT = {
  drinksShop: (id: IdDef) => `/shops/${id}/drinks`,
  shopLabels: (id: IdDef) => `/shops/${id}/label-sets`,
  labelOrders: (id: IdDef) => `/shops/${id}/orders`,
} as const;

export { API_END_POINT, API_STATUS_CODE };
