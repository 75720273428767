import type { ReactNode } from 'react';
import React from 'react';

import cs from 'classnames';

import IconLoading from '@/assets/icons/loading-small.svg';

type ButtonProps = {
  shouldStyleDisabled?: boolean;
  className?: string;
  btnType?: 'primary' | 'default';
  children: ReactNode;
  loading?: boolean;
  icon?: React.ReactNode;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'>;

const Button = ({
  shouldStyleDisabled = true,
  className,
  children,
  btnType = 'default',
  loading,
  icon,
  ...rest
}: ButtonProps) => {
  const classNames = cs(
    'rounded flex-inline-centered focus-visible:outline-none',
    className,
    {
      'disabled:bg-light-100 disabled:text-gray disabled:border-none':
        shouldStyleDisabled,
      'bg-white text-primary': btnType === 'default',
      'bg-primary text-white': btnType === 'primary',
    }
  );

  return (
    <button className={classNames} {...rest}>
      {children}
      {loading ? <IconLoading /> : icon}
    </button>
  );
};

export default Button;
