import create from 'zustand';
import { persist } from 'zustand/middleware';

import {
  CartSliceDef,
  OrderSliceDef,
  CommonSliceDef,
} from '@/models/cart.types';

import { cartSlice } from './slices/cartSlice';
import { commonSlice } from './slices/commonSlice';
import { qrCodeSlice } from './slices/qrCodeSlice';

type StoreState = CartSliceDef & OrderSliceDef & CommonSliceDef;

export const useAppStore = create<StoreState>()(
  persist(
    (...args) => ({
      ...cartSlice(...args),
      ...qrCodeSlice(...args),
      ...commonSlice(...args),
    }),
    {
      name: 'cart-storage',
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !['qrImage', 'shopName', 'isOutDated'].includes(key)
          )
        ),
    }
  )
);
