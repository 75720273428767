import React, { useState, useEffect } from 'react';

import Image from 'next/image';

import { MAIN_TEXT_DEFAULT_VALUE, MAIN_TEXT_FONT_SIZE_MAX } from '@/constants';
import { getTagFont } from '@/helpers';
import { TagFontDef } from '@/models/tagfont.types';

import TagFontItem from '../TagFontItem';

type LabelPreviewProps = {
  image: string;
  alt: string;
  imageClassName?: string;
  mainText: string | null;
  setTagFontError?: React.Dispatch<React.SetStateAction<string>>;
};

const LabelPreviewDetail = ({
  image,
  alt,
  mainText = '',
  imageClassName = '',
  setTagFontError,
}: LabelPreviewProps) => {
  const [tagFont, setTagFont] = useState({} as TagFontDef);

  useEffect(() => {
    const result = getTagFont(
      mainText || MAIN_TEXT_DEFAULT_VALUE,
      MAIN_TEXT_FONT_SIZE_MAX,
      MAIN_TEXT_DEFAULT_VALUE
    );
    setTagFont(result);
    setTagFontError?.(result?.validationError || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainText]);

  return (
    <div className="relative w-[499px] scale-label-mb tl:scale-label-tl pc:scale-label-pc border border-light-100">
      <Image
        width={0}
        height={0}
        className={imageClassName}
        src={image}
        alt={alt}
      />
      <div className="absolute w-[235px] top-[362px] left-[14px] bg-fill-text overflow-hidden">
        <TagFontItem tagFont={tagFont} fontSize={MAIN_TEXT_FONT_SIZE_MAX} />
      </div>
    </div>
  );
};

export default LabelPreviewDetail;
