import dynamic from 'next/dynamic';

const ComponentWithNoSSR = dynamic(() => import('./ComponentWithNoSSR'), {
  ssr: false,
});

export { default as Input } from '@/components/modules/Input';
export { default as Button } from '@/components/modules/Button';
export { default as Modal } from '@/components/modules/Modal';
export { default as Loading } from '@/components/modules/Loading';
export { default as Cart } from '@/components/modules/Cart';
export { default as LabelPreviewDetail } from '@/components/modules/LabelPreviewDetail';
export { default as TagFontItem } from '@/components/modules/TagFontItem';
export { default as DeviceRotationNotify } from '@/components/modules/DeviceRotationNotify';
export { default as LoadingOrder } from '@/components/modules/LoadingOrder';
export { default as SEOComponent } from '@/components/modules/SEO';
export { ComponentWithNoSSR };
