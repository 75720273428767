import Image from 'next/image';
import Link from 'next/link';

import IconChevronRight from '@/assets/icons/chevron-right.svg';
import LogoPC from '@/assets/images/logo-pc.svg';
import LogoTablet from '@/assets/images/logo-tablet.svg';
import Logo from '@/assets/images/logo.svg';
import ImageTopKeyVisual from '@/assets/images/top-keyvisual.gif';
import { Button } from '@/components/modules';
import { ROUTES_PATH } from '@/constants';
import { useRouterWithQueryParams } from '@/hooks';

import type { NextPageWithLayout } from './_app';

const Home: NextPageWithLayout = () => {
  const { generateUrlWithQueryParams } = useRouterWithQueryParams();
  const labelSelectionUrl = generateUrlWithQueryParams(
    ROUTES_PATH.labelSelection
  );

  return (
    <main className="h-full flex flex-col">
      <div className="w-full p-8 m-auto">
        <h1>
          <Logo className="tl:hidden mx-auto" />
        </h1>
        <div className="hidden tl:block">
          <h1>
            <LogoTablet className="hidden pc:hidden tl:block mx-auto" />
            <LogoPC className="hidden pc:block mx-auto" />
          </h1>
          <p className="text-center text-base pc:text-2xl font-black tl:mt-8">
            ラベルのテキストカスタマイズはこちら
          </p>
          <Image
            src={ImageTopKeyVisual}
            alt="key visual"
            className="mx-auto tl:w-[360px] pc:w-[540px] mt-12"
          />
        </div>
        <Link
          href={labelSelectionUrl}
          className="w-full tl:max-w-[328px] pc:max-w-[530px] mt-15 pc:mt-20 block mx-auto"
        >
          <Button
            btnType="primary"
            className="w-full h-14 pc:h-[70px] relative font-bold tl:text-15 pc:text-xl"
            icon={
              <IconChevronRight className="absolute right-2 top-1/2 -translate-y-1/2" />
            }
          >
            オリジナルラベルをつくる
          </Button>
        </Link>
      </div>
    </main>
  );
};

export default Home;
